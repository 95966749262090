* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.color-button {
  width: 14px;
  height: 14px;
  font-size: 0;
  margin: 2px;
  float: left;
  cursor: pointer;
}